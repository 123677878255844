import React from "react";
import styled, { css } from "styled-components";
import * as colors from "utils/constants/colors";
import { Tooltip } from "antd";
import {
  faCircleExclamation,
  faCircleMinus,
} from "@fortawesome/pro-solid-svg-icons";
import {
  DISABLED_SYSTEM_STATES,
  SYSTEM_STATE_TOOLTIP_MSG,
} from "utils/constants/clusterprofile";
import Icon from "components/ui/Icon";

const WarningIconWrapper = styled.div`
  margin-left: 10px;
`;

const WarningIcon = styled(Icon)`
  color: ${colors.yellow};
  font-size: 16px;
`;

const DisabledIcon = styled(Icon)`
  color: ${(props) => {
    if (props.state === "deleted") return colors.red;
    return colors.greenGray;
  }};
  font-size: 16px;
`;

const TooltipMessage = styled.span`
  ${(props) =>
    props.preview &&
    css`
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      font-style: normal;
      font-weight: normal;
    `}
  color: ${(props) => (props.preview ? "#555" : colors.white)};
`;

const PackSystemStateIcon = ({ preview, systemState }) => {
  if (![...DISABLED_SYSTEM_STATES, "deprecated"].includes(systemState)) {
    return null;
  }

  const getIcon = () => {
    if (DISABLED_SYSTEM_STATES.includes(systemState)) {
      return <DisabledIcon awesome={faCircleMinus} state={systemState} />;
    }
    return <WarningIcon awesome={faCircleExclamation} />;
  };

  return (
    <WarningIconWrapper>
      <Tooltip
        color={preview ? colors.white : colors.darkBlue}
        title={
          <TooltipMessage preview={preview}>
            {SYSTEM_STATE_TOOLTIP_MSG(systemState)}
          </TooltipMessage>
        }
      >
        {getIcon()}
      </Tooltip>
    </WarningIconWrapper>
  );
};

export default PackSystemStateIcon;
