import i18next from "i18next";
import ListActions from "modules/list/actions";
import api from "services/api";
import { generatePath } from "react-router";
import notifications from "services/notifications";
import {
  deleteEdgeMachine,
  EDGE_MACHINES_MODULE,
  addEdgeMachinesDrawer,
  editHostPairingKeyModal,
  EDIT_HOST_PAIRING_KEY,
  edgeMachineDetailsFetcher,
} from "state/cluster/services/edgemachines";
import { EdgeMachineSchema } from "utils/schemas";
import createFormActions from "modules/form/actions";
import store, { getStoreEntity } from "services/store";
import { formatTags } from "utils/presenters";
import { parseAppliances, parseTagsForInput } from "utils/parsers";
import history from "services/history";
import { CLUSTERS } from "utils/constants/routes";
import Validator from "services/validator";
import {
  KUBERNETES_NAME_RULES,
  areValidKubernetesTags,
} from "services/validator/rules";
import { APPLIANCE_STATES } from "utils/constants";

function getFilterPayload(tenantFilters = []) {
  return {
    conjunction: "and",
    filters: tenantFilters.map(({ values, property, operator }) => ({
      property,
      type: "string",
      condition: {
        string: {
          operator,
          negation: false,
          match: {
            conjunction: "or",
            values,
          },
          ignoreCase: false,
        },
      },
    })),
  };
}

// It will need load on scroll, maybe find a way to use a fetcher
export const edgeMachinesListActions = new ListActions({
  hasPagination: true,
  schema: [EdgeMachineSchema],
  initialQuery() {
    return {
      search: "",
      limit: 20,
      states: APPLIANCE_STATES.filter((state) => state.value !== "in-use").map(
        (state) => state.value
      ),
    };
  },
  async fetchData(query) {
    const {
      offset,
      limit,
      continue: continueToken,
      states,
      search,
      architecture,
    } = query;
    const apiUrl = "v1/dashboard/edgehosts/search";
    const edgeHostStates = !states.length
      ? APPLIANCE_STATES.map((state) => state.value)
      : states;
    const tenantFilters = [];

    if (search) {
      tenantFilters.push({
        property: "name",
        values: [search],
        operator: "contains",
      });
    }

    if (edgeHostStates.length) {
      tenantFilters.push({
        property: "state",
        values: edgeHostStates,
        operator: "eq",
      });
    }

    if (architecture) {
      tenantFilters.push({
        property: "architecture",
        values: [architecture],
        operator: "eq",
      });
    }

    const payload = {
      filter: {
        conjuction: "and",
        filterGroups: [getFilterPayload(tenantFilters)],
      },
      sort: [],
    };

    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    const data = await api.post(
      `${apiUrl}?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );

    return {
      ...data,
      items: parseAppliances(data?.items || []),
    };
  },
});

const addMachinesValidator = new Validator();
addMachinesValidator.addRule(
  ["machineIds"],
  areValidKubernetesTags({
    errorMessageText: () =>
      i18next.t(
        "Some Edge Host IDs don't respect the kubernetes naming guidelines"
      ),
    tooltipPlacement: "bottomRight",
    onlySpectroTags: true,
    rules: KUBERNETES_NAME_RULES,
  })
);

export const addEdgeMachinesFormActions = createFormActions({
  validator: addMachinesValidator,
  init: () =>
    Promise.resolve({
      machineIds: [],
      machinesTags: {},
      machinesPairingKey: {},
      machineArchType: {},
    }),
  submit: async (data) => {
    const promises = data.machineIds.map((edgeHostUid) =>
      api.post("v1/edgehosts", {
        metadata: {
          name: edgeHostUid,
          uid: edgeHostUid,
          labels: formatTags(data.machinesTags[edgeHostUid] || []),
        },
        spec: {
          hostPairingKey: data.machinesPairingKey[edgeHostUid] || "",
          archType: data.machineArchType[edgeHostUid] || "",
        },
      })
    );

    const result = await Promise.allSettled(promises);

    let hasRejection = false;
    const invalidMachines = [];

    result.forEach(({ status, reason, value }, index) => {
      if (status === "rejected") {
        notifications.error({
          message: i18next.t("Something went wrong"),
          description: reason?.message,
        });

        invalidMachines.push(data.machineIds[index]);
        hasRejection = true;
      }

      if (status === "fulfilled") {
        notifications.success({
          message: i18next.t(
            'Machine "{{machineUid}}" has been added successfully',
            { machineUid: value?.uid }
          ),
        });

        const updatedValues = [...data.machineIds];
        updatedValues.splice(index, 1);
        store.dispatch(
          addEdgeMachinesFormActions.onChange({
            module: EDGE_MACHINES_MODULE,
            name: "machineIds",
            value: updatedValues,
          })
        );
      }
    });

    if (hasRejection) {
      store.dispatch(
        addEdgeMachinesFormActions.updateErrors({
          module: EDGE_MACHINES_MODULE,
          errors: [
            {
              result: true,
              field: "machineIds",
              invalidTags: [...invalidMachines],
            },
          ],
        })
      );

      throw new Error();
    }

    store.dispatch(edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE));
  },
});

export function openAddEdgeMachineDrawer() {
  return (dispatch) => {
    addEdgeMachinesDrawer
      .open()
      .then(() =>
        dispatch(
          addEdgeMachinesFormActions.submit({ module: EDGE_MACHINES_MODULE })
        )
      );
  };
}

export function onMachineDelete({ uid, name, projectUid }) {
  return (dispatch, getState) => {
    const isDetailsPage = getState().location.params.id;

    const headers = projectUid ? { ProjectUid: projectUid } : {};

    deleteEdgeMachine.open({ machineName: name }).then(async () => {
      try {
        await api.delete(`v1/edgehosts/${uid}`, null, { headers });
      } catch (e) {
        notifications.error({
          message: i18next.t(
            "Something went wrong when trying to delete the edge machine"
          ),
          description: e?.message,
        });
        return;
      }

      notifications.success({
        message: i18next.t('Machine "{{name}}" has been deleted', { name }),
      });

      dispatch(edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE));

      if (isDetailsPage) {
        history.push(generatePath(CLUSTERS.ROOT, { tab: "appliances" }));
      }
    });
  };
}

const editPairingKeyValidator = new Validator();
export const editPairingKeyActions = createFormActions({
  validator: editPairingKeyValidator,
  async init() {
    const machineDetails = getStoreEntity(
      editHostPairingKeyModal.data.guid,
      EdgeMachineSchema
    );

    return {
      hostPairingKey: "",
      tags: parseTagsForInput(machineDetails?.metadata?.labels) || [],
    };
  },
  submit: async (data) => {
    const machineDetails = getStoreEntity(
      editHostPairingKeyModal.data.guid,
      EdgeMachineSchema
    );

    const uid = machineDetails?.metadata?.uid;

    const metadataPayload = {
      metadata: {
        labels: formatTags(data.tags),
        name: machineDetails?.metadata?.name,
        uid: machineDetails?.metadata?.uid,
      },
    };

    const headers = machineDetails?.spec?.projectMeta?.uid
      ? { ProjectUid: machineDetails?.spec?.projectMeta?.uid }
      : {};

    const promises = [
      api.put(`v1/edgehosts/${uid}/meta`, metadataPayload, { headers }),
    ];

    if (data.hostPairingKey) {
      promises.push(
        api.patch(
          `v1/edgehosts/${uid}/hostPairingKey`,
          {
            hostPairingKey: data.hostPairingKey,
          },
          { headers }
        )
      );
    }

    try {
      await Promise.all(promises);
      notifications.success({
        message: i18next.t(
          'Machine "{{machineUid}}" has been updated successfully',
          { machineUid: uid }
        ),
      });
    } catch (err) {
      notifications.error({
        message: i18next.t("Something went wrong"),
        description: err?.message,
      });
      return;
    }
  },
});

export function onHostPairingKeyEdit({ guid }) {
  return (dispatch) => {
    const machineDetails = getStoreEntity(guid, EdgeMachineSchema);
    const machineUid = machineDetails.metadata.uid;
    const isDetailsPage = store.getState().location.params.id;

    editHostPairingKeyModal.open({ guid }).then(async () => {
      await dispatch(
        editPairingKeyActions.submit({ module: EDIT_HOST_PAIRING_KEY })
      );

      if (isDetailsPage) {
        await dispatch(edgeMachineDetailsFetcher.fetch(machineUid));
      } else {
        await dispatch(
          edgeMachinesListActions.fetchItems(EDGE_MACHINES_MODULE)
        );
      }
    });
  };
}

export function onMachineIdsChange(value) {
  return (dispatch, getState) => {
    dispatch(
      addEdgeMachinesFormActions.batchChange({
        module: EDGE_MACHINES_MODULE,
        updates: {
          machineIds: value,
          machineArchType: value.reduce((acc, curr) => {
            const machineArchType =
              getState().forms?.edgeMachines.data.machineArchType;

            if (!machineArchType[curr]) {
              acc[curr] = "amd64";
            }

            return { ...machineArchType, ...acc };
          }, {}),
        },
      })
    );

    const errors = getState().forms?.edgeMachines?.errors || [];

    if (errors.length > 0) {
      const updatedErrors = errors.map((error) => {
        if (error.field === "machineIds") {
          error.invalidTags = [...error.invalidTags].filter((tag) =>
            value.includes(tag)
          );
          if (error.invalidTags.length === 0) {
            error.result = false;
          }
        }

        return error;
      });

      dispatch(
        addEdgeMachinesFormActions.updateErrors({
          module: EDGE_MACHINES_MODULE,
          errors: updatedErrors,
        })
      );

      dispatch(
        addEdgeMachinesFormActions.validateField({
          module: EDGE_MACHINES_MODULE,
          name: "machineIds",
        })
      );
    }
  };
}
