import { v4 as uuid } from "uuid";
import i18next from "i18next";

import Validator from "services/validator";
import store from "services/store";
import createFormActions from "modules/form/actions";
import fetchers, {
  PROFILE_TYPES_FORM_MODULES,
} from "modules/profileBuilder/services";

import {
  getRepositories,
  getSelectedRepository,
  getSelectedLayer,
  getSelectedPackVersion,
  getCurrentSidebarStep,
  getLayers,
  getSelectedClusterProfileTemplate,
  getPackVersions,
  getReversedLayers,
  areAllLayersConfigured,
  getSelectedVersionParent,
  getSelectedVersionValues,
  getFormattedPayload,
  getCurrentLayerIndex,
  getFilteredExpandedKeys,
  getSelectedVersionDefaultValues,
  getSelectedVersionPresets,
} from "state/clusterprofile/selectors/layerConfig";
import { systemProfileBuilderEditModule } from "state/profiles/system/services/create";

import {
  Missing,
  areValidKubernetesTags,
  DebouncedRule,
  SemanticVersion,
  isValidTagSelection,
} from "services/validator/rules";
import historyService from "services/history";
import notifications from "services/notifications";
import api from "services/api/index";
import ModalService from "services/modal";

import { PackVersionSchema, PackSchema } from "utils/schemas";
import { PACK_STEPS } from "utils/constants/clusterprofile";
import {
  parseYAMLValues,
  getDefaultPresetsFromValues,
  getPreviousPresetDelta,
} from "utils/parsers";
import AsyncAction from "modules/asyncAction";

import {
  ADD_GUID,
  LAYERS,
  OS_GUID,
} from "state/clusterprofile/reducers/layerConfig";
import {
  getRawClusterProfile,
  isClusterProfileUsed,
} from "state/clusterprofile/selectors/details";
import { getCurrentStep } from "state/clusterprofile/selectors/create";

import {
  repositoriesFetcher,
  packNamesFetcher,
  configurePacksModal,
  profileBuilderCreateModule,
  profileBuilderEditModule,
} from "../services";
import {
  fetchClusterProfile,
  fetchResolvedValues,
} from "state/clusterprofile/actions/details";
import { appProfileFormActions } from "state/profiles/system/actions/create/form";
import { extractInstallOrder, extractPackDisplayName } from "utils/yaml";
import { getAllRepos } from "modules/profileBuilder/selectors";
import { createTwoWaySyncer } from "utils/editor/formMode";
import { COXEDGE_ENVIRONMENT } from "utils/constants";

//

export const CLUSTER_PROFILE_MODULE = "clusterprofile";
const reservedTags = ["microsoft", "azure", "windows"];

export const validator = new Validator();

const validateClusterprofileName = async (value, key, data) => {
  let error;

  if (data.persisted) {
    return false;
  }

  const version = data.version || "1.0.0";
  const promise = api.get(
    `v1/clusterprofiles/validate/name?name=${value}&version=${version}`
  );

  try {
    await promise;
  } catch (err) {
    if (err.code === "ClusterProfileAlreadyExists") {
      error = {
        result: i18next.t(
          `A cluster profile with the name "{{ value }}" and version "{{ version }}" already exists`,
          {
            value,
            version,
          }
        ),
        field: "name",
      };
    }
  }

  return error || false;
};

const nameValidationRule = DebouncedRule()(validateClusterprofileName);

validator.addRule(["name", "cloudType"], Missing());
validator.addRule(["name"], (value, key, data) => {
  if (!value) {
    return;
  }
  const promise = nameValidationRule(value, key, data);
  store.dispatch({
    type: "PROFILE_NAME_VALIDATION",
    promise,
  });
  return promise;
});
validator.addRule(["tags"], areValidKubernetesTags());
validator.addRule(["tags"], (value) => {
  return isValidTagSelection({ reservedTags })(value);
});

validator.addRule(["version"], SemanticVersion());

export const clusterProfileFormActions = createFormActions({
  validator,
  async init() {
    const { location } = store.getState().router;
    store.dispatch(fetchers.repositoriesFetcher.flushCache());
    store.dispatch(fetchers.helmRepositoriesFetcher.flushCache());

    if (location.pathname.includes("/create")) {
      initProfileBuilder("cluster", "aws");
      return Promise.resolve({
        persisted: false,
        name: "",
        version: "",
        description: "",
        tags: [],
        layers: LAYERS,
        profileType: "cluster",
        cloudType: "aws",
      });
    }

    const clusterprofile = getRawClusterProfile(store.getState());
    await store.dispatch(fetchers.allRepositoriesFetcher.fetch());
    await store.dispatch(fetchers.ociRepositoriesFetcher.fetch());
    const repositories = getAllRepos(store.getState());
    const inUse = isClusterProfileUsed(store.getState());

    const zarfRepositories = repositories
      .filter((repo) => repo.kind === "zarf")
      .map((repo) => repo.uid);

    if (clusterprofile) {
      const { metadata } = clusterprofile;

      await store.dispatch(fetchClusterProfilePacks(metadata.uid));

      let clusterProfileTemplate = getSelectedClusterProfileTemplate(
        store.getState()
      );

      clusterProfileTemplate = getSelectedClusterProfileTemplate(
        store.getState()
      );
      const layers = clusterProfileTemplate.packs.map(async (packVersion) => {
        const { pack } = packVersion;
        let type = pack.spec.addonType || pack.spec.layer;
        let extraProps = {};

        if (pack.spec.type === "manifest") {
          type = "manifest";
          extraProps = {
            formType: "manifest",
          };
        }
        let isIntegration =
          !!pack.spec?.template?.parameters?.inputParameters?.length;
        let integrationParams = {};
        if (isIntegration) {
          type = pack.spec.addonSubType || pack.spec.layer;
          const syncer = createTwoWaySyncer({
            formMeta: pack.spec.template?.parameters?.inputParameters || [],
            values: packVersion.values,
          });
          integrationParams = syncer.populateForm();
        }
        if (
          pack.spec.type === "oci" &&
          !(pack.spec.addonType || pack.spec.layer)
        ) {
          type = "helmChart";
        }

        if (
          pack.spec.type === "oci" &&
          pack.spec.addonType === "ociHelmChart"
        ) {
          type = "helmChart";
        }

        if (zarfRepositories.includes(pack.spec.registryUid)) {
          type = "zarf";
        }

        const registryData = repositories.find(
          (repo) => repo.uid === pack.spec.registryUid
        );

        const manifestMap =
          (packVersion?.manifests
            ? packVersion.manifests
            : pack?.spec?.manifests) || [];

        if (manifestMap.length) {
          let manifestContent = [];
          try {
            manifestContent = await api.get(
              `v1/clusterprofiles/${metadata.uid}/packs/${pack.metadata.name}/manifests`
            );
          } catch (err) {}
          manifestContent.items.forEach((manifest, index) => {
            manifestMap[index].content = manifest.spec.published.content;
          });
        }

        let accessType = "public";
        if (
          registryData?.kind === "helm" &&
          (registryData?.isOci || registryData?.isPrivate)
        ) {
          accessType = "private";
        }

        const layer = {
          type,
          accessType,
          annotations: pack.spec.annotations || {},
          guid: pack.metadata.name,
          initialPackName: pack.metadata.name,
          readOnly:
            inUse &&
            pack.spec.layer === "k8s" &&
            clusterprofile.spec?.published?.cloudType ===
              COXEDGE_ENVIRONMENT.apiKey,
          persisted: true,
          ...extraProps,
          config: {
            ...integrationParams,
            packUid: pack.metadata.uid,
            uid: pack.metadata.uid,
            packVersionGuid: packVersion.guid,
            name: pack.metadata.name,
            tag: packVersion.tag,
            registryUid: pack.spec.registryUid,
            values: packVersion.values,
            readme: pack.spec.readme,
            logo: pack.spec.logoUrl,
            presets: pack.spec.presets,
            template: pack.spec.template,
            schema: pack.spec.schema,
            isOci: pack.spec.type === "oci",
            isIntegration,
            isInvalid: packVersion?.isInvalid || false,
            inValidReason: packVersion?.inValidReason || "",
            manifests: manifestMap,
          },
        };

        layer.config.installOrder = extractInstallOrder(layer.config);
        layer.config.displayName = extractPackDisplayName(layer.config);

        return layer;
      });

      const layersData = await Promise.allSettled(layers);

      let variables = [];
      try {
        variables =
          (await api.get(`v1/clusterprofiles/${metadata.uid}/variables`))
            ?.variables || [];
      } catch (err) {}
      profileBuilderEditModule.actions.initialize({
        layers: layersData
          .filter((data) => data.status === "fulfilled")
          .map((layer) => layer.value),
        isEditMode: true,
        isClusterProfileUsed: inUse,
        cloudType: clusterProfileTemplate?.cloudType || "",
        profileUid: metadata.uid,
        variables: variables.map((variable) => ({
          ...variable,
          useRegex: !!variable.regex,
          useDefaultValue: !!variable.defaultValue,
          isMasked: !!variable.isSensitive,
          isRequired: !!variable.required,
          isReadOnly: !!variable.immutable,
          isHidden: !!variable.hidden,
          guid: uuid(),
          isPersisted: true,
        })),
      });
      try {
        const response = await store.dispatch(
          fetchResolvedValues(metadata.uid)
        );
        profileBuilderEditModule.actions.updateResolvedValues(
          response?.resolved
        );
      } catch (err) {}

      return Promise.resolve({
        persisted: true,
        name: metadata.name,
        cloudType: clusterProfileTemplate?.cloudType || "all",
        profileType: clusterProfileTemplate?.type || "cluster",
        description: metadata.annotations?.description || "",
        tags: (metadata?.labels && Object.keys(metadata?.labels)) || [],
        layers,
      });
    }

    return {
      persisted: false,
      name: "",
      profileType: "cluster",
      description: "",
      tags: [],
      layers: LAYERS,
    };
  },
  async submit(formData) {
    const payload = getFormattedPayload(store.getState());
    let promise;
    const { metadata } = getRawClusterProfile(store.getState()) || {};
    if (formData.persisted) {
      payload.metadata.uid = metadata.uid;
      payload.metadata.labels = metadata.labels;
      promise = api.put(`v1/clusterprofiles/${metadata.uid}`, payload);
    } else {
      promise = api.post("v1/clusterprofiles", payload);
    }

    try {
      const response = await promise;
      const publishPromise = api.patch(
        `v1/clusterprofiles/${response.uid || metadata.uid}/publish`
      );

      await publishPromise;

      if (!formData.persisted) {
        historyService.push("/profiles/cluster");
      }

      notifications.withAudit({
        message: i18next.t(
          "Cluster profile {{profileName}} was saved and published",
          { profileName: formData.name }
        ),
        ...(formData.persisted && {
          promise: publishPromise,
        }),
      });
    } catch (error) {
      const message = formData.persisted
        ? i18next.t("Something went wrong when editing the cluster profile")
        : i18next.t("Something went wrong when creating the cluster profile");

      notifications.error({
        message,
        description: error.message,
      });
    }

    return promise;
  },
});

function fetchClusterProfilePacks(uid) {
  return function thunk(dispatch) {
    const promise = api
      .get(`v1/clusterprofiles/${uid}/packs?includePackMeta=schema,presets`)
      .then((res) => res.items);

    dispatch({
      type: "FETCH_SELECTED_CLUSTER_PROFILE_PACKS",
      promise,
      schema: [PackSchema],
    });

    return promise;
  };
}

export function resetForwardSteps() {
  return (dispatch, getState) => {
    const state = getState();
    const steps = state.clusterprofile.create?.steps || [];
    const currentStep = getCurrentStep(state);

    steps.forEach((step, index) => {
      if (index >= currentStep) {
        dispatch({
          type: "CLUSTER_PROFILE_SET_DESCRIPTION",
          stepId: step.id,
          description: "",
        });
      }
    });
  };
}

function initProfileBuilder(profileType, cloudType = "") {
  let layers = [...LAYERS]
    .reverse()
    .map((layer) => ({ ...layer, persisted: true }));

  if (cloudType === "edge-native") {
    layers = layers.filter((layer) => layer.type !== "csi");
  }
  if (profileType === "add-on") {
    layers = [];
  }

  profileBuilderCreateModule.actions.initialize({ layers, cloudType });
}

export function onChangeField(name, value) {
  const props = { name, value, module: CLUSTER_PROFILE_MODULE };
  return (dispatch, getState) => {
    dispatch(clusterProfileFormActions.onChange(props));

    if (name === "profileType") {
      dispatch(
        clusterProfileFormActions.onChange({
          module: CLUSTER_PROFILE_MODULE,
          name: "cloudType",
          value: value === "add-on" ? "all" : "aws",
        })
      );
    }

    if (name === "cloudType" || name === "profileType") {
      const profileType = getState().forms.clusterprofile?.data?.profileType;
      const cloudType = getState().forms.clusterprofile?.data?.cloudType;
      initProfileBuilder(profileType, cloudType);
      dispatch(resetForwardSteps());
    }

    if (name === "version") {
      dispatch(
        clusterProfileFormActions.validateField({
          name: "name",
          module: CLUSTER_PROFILE_MODULE,
        })
      );
    }
  };
}

export function onTreeSearch(value) {
  return function thunk(dispatch, getState) {
    dispatch({
      type: "VERSION_TREE_SEARCH",
      treeSearchValue: value,
    });

    dispatch(setVersionExpandedKeys(getFilteredExpandedKeys(getState())));
  };
}

export function deselectPack() {
  return function thunk(dispatch, getState) {
    const selectedLayer = getSelectedLayer(getState());
    const currentStep = getCurrentSidebarStep(getState());
    const steps = PACK_STEPS[selectedLayer.type];
    const stepIndex = steps.indexOf(currentStep);
    const prevStep = steps[stepIndex - 1];

    dispatch(setPackStep(prevStep));
  };
}

export function fetchRepositories() {
  return repositoriesFetcher.fetch();
}

export function setAvailableRepositories(repositories) {
  return (dispatch) => {
    dispatch({
      type: "SET_AVAILABLE_REPOSITORIES",
      availableRepositories: repositories,
    });
  };
}

function packTagsCompare(tag1, tag2) {
  const [major1, minor1, patch1] = tag1.version
    .split(".")
    .map((versionType) => parseInt(versionType));
  const [major2, minor2, patch2] = tag2.version
    .split(".")
    .map((versionType) => parseInt(versionType));

  if (major1 < major2) {
    return 1;
  }

  if (major1 === major2 && minor1 < minor2) {
    return 1;
  }

  if (minor1 === minor2 && patch1 < patch2) {
    return 1;
  }

  return -1;
}

export function fetchPackVersions() {
  return function thunk(dispatch, getState) {
    const selectedRepository = getSelectedRepository(getState());
    const packName = getState().forms.packSteps.data.name;
    const cloudType = getState().forms.clusterprofile.data.cloudType;

    const promise = api.get(
      `v1/packs/${packName}/registries/${selectedRepository.metadata.uid}?cloudType=${cloudType}`
    );
    dispatch({
      promise: promise.then((res) =>
        res.tags.sort(packTagsCompare).map((version) => ({
          ...version,
          pack: {
            metadata: {
              uid: version.packUid,
            },
          },
        }))
      ),
      type: "FETCH_PACK_VERSIONS",
      schema: [PackVersionSchema],
    });
    const packsPromise = promise
      .then((res) => {
        return res.packValues.map((pack) => ({
          metadata: { name: res.name, uid: pack.packUid },
          spec: pack,
        }));
      })
      .catch((err) => {
        notifications.warn({
          message: i18next.t("Unable to find the package"),
          description: i18next.t("Please go back and select another"),
        });
      });
    dispatch({
      promise: packsPromise,
      type: "FETCH_PACKS",
      schema: [PackSchema],
    });

    return packsPromise;
  };
}

export function fetchPackNames() {
  return function thunk(dispatch) {
    return dispatch(packNamesFetcher.fetch());
  };
}

const packSelectorValidator = new Validator();
packSelectorValidator.addRule(
  "name",
  Missing({ message: () => i18next.t("You need to select a pack") })
);
packSelectorValidator.addRule(
  "version",
  Missing({ message: () => i18next.t("You need to select a pack version") })
);

function getLayerConfig() {
  const state = store.getState();
  const layer = getSelectedLayer(state);

  let formData = {
    type: layer?.type,
    name: layer?.config?.name || "",
    version: layer?.config?.version || "",
    values: layer?.config?.values || "",
  };

  if (layer && layer.type === "k8s") {
    return Promise.resolve({
      ...formData,
      name: "kubernetes",
      type: "k8s",
    });
  }

  return Promise.resolve(formData);
}

export const packsStepFormActions = createFormActions({
  init() {
    return getLayerConfig();
  },
  submit() {
    store.dispatch(applyParams());
  },
  validator: packSelectorValidator,
});

export function selectLayer(layerGuid) {
  return async (dispatch, getState) => {
    dispatch({
      type: "SELECT_LAYER",
      selectedLayer: layerGuid,
    });

    if (!layerGuid) {
      return;
    }

    await dispatch(packsStepFormActions.init({ module: "packSteps" }));
    const layer = getSelectedLayer(getState());
    const formData = await getLayerConfig();
    dispatch(
      packsStepFormActions.batchChange({
        module: "packSteps",
        updates: formData,
      })
    );

    if (layer.type === "new") {
      dispatch(setPackStep("type"));
      return;
    }

    // When editing a pack, go straight to version step and fetch the pack names to get pack label and icon
    const step = formData.name ? "version" : "name";

    dispatch(setPackStep(step));
  };
}

export function selectHeaderLayer(layerGuid) {
  return (dispatch) => {
    dispatch(applyParams());
    dispatch(selectLayer(layerGuid));
  };
}

export function applyParams() {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedPackVersion = getSelectedPackVersion(state);
    const selectedRepository = getSelectedRepository(state);

    if (!selectedPackVersion) {
      return;
    }

    const formData = state.forms.packSteps.data;

    const selectedLayer = state.clusterprofile.layerConfig.selectedLayer;
    const layerIndex = getLayers(state).findIndex(
      (layer) => layer.guid === selectedLayer
    );

    const config = {
      packUid: selectedPackVersion.packUid,
      name: selectedPackVersion?.pack?.metadata?.name,
      tag: selectedPackVersion.tag,
      version: selectedPackVersion.guid,
      values: formData.values,
      registryUid: selectedRepository.metadata.uid,
      presets: formData.presets,
    };

    dispatch(
      clusterProfileFormActions.onChange({
        value: config,
        name: `layers.${layerIndex}.config`,
        module: CLUSTER_PROFILE_MODULE,
      })
    );

    await dispatch(
      clusterProfileFormActions.validateField({
        name: `layers.${layerIndex}.config`,
        module: CLUSTER_PROFILE_MODULE,
      })
    );

    const errors = getState().forms[CLUSTER_PROFILE_MODULE].errors;
    if (errors.length === 0) {
      notifications.close("layer-validations-error");
    }
  };
}

export function addNewLayer() {
  return function thunk(dispatch) {
    dispatch({
      type: "SELECT_LAYER",
      selectedLayer: ADD_GUID,
    });
    dispatch({
      type: "SET_PACK_STEP",
      step: "type",
    });
  };
}

export function submitClusterProfile() {
  return async function thunk(dispatch, getState) {
    const clusterprofile = getRawClusterProfile(getState());
    const profileType = clusterprofile?.spec?.published?.type;

    let builderModule = profileBuilderEditModule;
    let profileFormActions = clusterProfileFormActions;

    if (profileType === "system") {
      builderModule = systemProfileBuilderEditModule;
      profileFormActions = appProfileFormActions;
    }

    await dispatch(
      profileFormActions.submit({
        module: PROFILE_TYPES_FORM_MODULES[profileType],
      })
    );

    // NOTE: to allow navigating away immediately we will temporarily initialize
    // the profile builder with empty layers
    builderModule.actions.initialize({
      layers: [],
      isEditMode: true,
      isClusterProfileUsed: isClusterProfileUsed(store.getState()),
    });

    await dispatch(
      fetchClusterProfile(
        clusterprofile.metadata.uid,
        "REFRESH_CLUSTER_PROFILE_DETAILS"
      )
    );
    dispatch(
      profileFormActions.init({
        module: PROFILE_TYPES_FORM_MODULES[profileType],
      })
    );
  };
}

export const saveClusterProfileAsyncAction = new AsyncAction({
  promise: async () => {
    const clusterprofile = getRawClusterProfile(store.getState());
    const profileType = clusterprofile?.spec?.published?.type;

    let builderModule = profileBuilderEditModule;

    if (profileType === "system") {
      builderModule = systemProfileBuilderEditModule;
    }

    const formData =
      store.getState().forms[PROFILE_TYPES_FORM_MODULES[profileType]].data;
    await builderModule.actions.validateProfilePacks(formData, true);
    await store.dispatch(submitClusterProfile());
  },
});

export function setSelectedRepository(repository) {
  return function thunk(dispatch) {
    dispatch({
      type: "UPDATE_SELECTED_REPOSITORY",
      repository,
    });
    dispatch(
      packsStepFormActions.batchChange({
        module: "packSteps",
        updates: {
          version: undefined,
          values: undefined,
          presets: undefined,
        },
      })
    );
    dispatch(fetchPackVersions());
  };
}

export function setPackStep(step) {
  return async function thunk(dispatch, getState) {
    dispatch({
      type: "SET_PACK_STEP",
      step,
    });
    if (step === "name") {
      dispatch(fetchPackNames());
    }

    if (step === "version") {
      dispatch(fetchPackNames());
      dispatch(fetchAvailableRepositories());
      dispatch(setPresetsOptions());

      const initialSelectedLayer = getSelectedLayer(getState());
      const repositories =
        getState().clusterprofile.layerConfig.availableRepositories;

      const selectedRepository = repositories.find(
        (repository) =>
          repository.metadata.uid === initialSelectedLayer?.config?.registryUid
      );
      dispatch({
        type: "UPDATE_SELECTED_REPOSITORY",
        repository: selectedRepository?.guid || repositories[0]?.guid,
      });
      await dispatch(fetchPackVersions());
      const currentLayer = getSelectedLayer(getState());
      if (initialSelectedLayer?.guid !== currentLayer?.guid) {
        return;
      }

      const state = getState();
      const selectedPackVersion = getSelectedPackVersion(state);
      const packVersions = getPackVersions(state);
      const updatedPackVersion = packVersions.find((packVersion) => {
        return (
          packVersion.packUid === selectedPackVersion?.packUid &&
          selectedPackVersion?.tag === packVersion.tag
        );
      });

      if (updatedPackVersion) {
        dispatch(
          packsStepFormActions.onChange({
            name: "version",
            value: updatedPackVersion.guid,
            module: "packSteps",
          })
        );
      }

      const selectedParentVersion = getSelectedVersionParent(getState())?.guid;
      if (selectedParentVersion) {
        dispatch(setVersionExpandedKeys([selectedParentVersion]));
      }
    }
  };
}

export function setPackValues() {
  return (dispatch, getState) => {
    const packValues = getSelectedVersionValues(getState());
    dispatch(
      packsStepFormActions.onChange({
        name: "values",
        value: packValues,
        module: "packSteps",
      })
    );
    dispatch(applyParams());
  };
}

function fetchAvailableRepositories() {
  return function (dispatch, getState) {
    const state = getState();
    const currentPackName = state.forms.packSteps.data.name;
    const packNames = packNamesFetcher.selector(state).result;
    const selectedPackName = packNames?.find(
      (pack) => pack.value === currentPackName
    );
    if (selectedPackName) {
      const repositories = getRepositories(state).filter((repository) =>
        selectedPackName.registries.includes(repository.metadata.uid)
      );
      dispatch(setAvailableRepositories(repositories));
    } else {
      const repositories = getRepositories(state);
      dispatch(setAvailableRepositories(repositories));
    }
  };
}

function setPresetsOptions() {
  return function (dispatch, getState) {
    const state = getState();
    const presets = getSelectedVersionPresets(state);

    dispatch(
      packsStepFormActions.onChange({
        module: "packSteps",
        name: "presets",
        value: presets,
      })
    );
  };
}

// TODO: Might need some refactoring
export function onPacksFormChange(name, value) {
  return function thunk(dispatch, getState) {
    const state = getState();
    const selectedLayer = getSelectedLayer(state);
    const currentStep = getCurrentSidebarStep(state);
    const steps = PACK_STEPS[selectedLayer.type];
    const stepIndex = steps.indexOf(currentStep);
    const nextStep = steps[stepIndex + 1];

    dispatch(
      packsStepFormActions.onChange({
        module: "packSteps",
        name,
        value,
      })
    );

    if (name === "values") {
      dispatch(applyParams());
      return;
    }

    if (currentStep === "name") {
      dispatch(setPackName());
      if (state.forms.packSteps.data?.name !== value) {
        dispatch(
          packsStepFormActions.onChange({
            module: "packSteps",
            name: "version",
            value: null,
          })
        );
      }
      dispatch(setPackStep(nextStep));
    }

    if (currentStep === "version") {
      dispatch(setPresetsOptions());
      dispatch(setPackValues());
    }

    if (currentStep === "type") {
      const layers = getLayers(getState());
      let newLayers = [];

      if (selectedLayer.type === "new") {
        const guid = uuid();
        newLayers = [{ guid, type: value, config: null }, ...layers];

        dispatch({
          type: "ADD_LAYER",
          guid,
          layerType: value,
        });
      } else {
        if (selectedLayer.type === value) {
          dispatch(setPackStep(nextStep));
          return;
        }

        newLayers = layers.map((layer) => {
          if (layer.guid === selectedLayer.guid) {
            return {
              guid: layer.guid,
              type: value,
              config: null,
            };
          }
          return layer;
        });

        dispatch(
          packsStepFormActions.batchChange({
            module: "packSteps",
            updates: {
              type: value,
              name: "",
              version: "",
              values: "",
            },
          })
        );
      }

      dispatch(
        clusterProfileFormActions.onChange({
          module: CLUSTER_PROFILE_MODULE,
          name: "layers",
          value: newLayers,
        })
      );
      dispatch(setPackStep(nextStep));
    }
  };
}

export function deleteLayer(layerGuid) {
  return function thunk(dispatch, getState) {
    const layers = getState().forms.clusterprofile.data.layers.filter(
      (layer) => layer.guid !== layerGuid
    );

    const errors = getState().forms.clusterprofile.errors;
    const layerError = errors.find((error) => error.layerGuid === layerGuid);

    if (layerError) {
      layerError["result"] = null;
    }

    // pretty sure this doesn't work as it should
    dispatch({
      type: "UPDATE_FORM_ERRORS",
      module: "clusterprofile",
      errors,
    });
    dispatch({
      type: "FORM_ON_CHANGE",
      module: CLUSTER_PROFILE_MODULE,
      name: "layers",
      value: layers,
    });
    dispatch(setPackStep("preview"));
    dispatch(selectLayer(""));
  };
}

export function openConfigureClusterProfileModal() {
  return (dispatch, getState) => {
    dispatch(packsStepFormActions.init({ module: "packSteps" }));
    //TODO: to be removed once we get confirmation on cancel and clear all layers config, since it will always be OS layer
    const firstUnconfiguredLayer = getReversedLayers(getState()).find(
      (layer) => !layer?.config?.version
    );
    dispatch(selectLayer(firstUnconfiguredLayer?.guid));
    configurePacksModal.open();
  };
}

export function setPackName() {
  return (dispatch, getState) => {
    const state = getState();
    const formData = state.forms.packSteps.data;

    const selectedLayer = state.clusterprofile.layerConfig.selectedLayer;
    const layerIndex = getLayers(state).findIndex(
      (layer) => layer.guid === selectedLayer
    );

    const config = {
      name: formData?.name,
    };

    dispatch(
      clusterProfileFormActions.onChange({
        value: config,
        name: `layers.${layerIndex}.config`,
        module: CLUSTER_PROFILE_MODULE,
      })
    );
  };
}

export function configureLayer(guid) {
  return (dispatch) => {
    dispatch(selectLayer(guid));
    configurePacksModal.open();
  };
}

export function setNextLayer() {
  return (dispatch, getState) => {
    const currentLayerIndex = getCurrentLayerIndex(getState());
    const layers = getReversedLayers(getState());

    if (currentLayerIndex === layers.length - 1) {
      dispatch(selectLayer(ADD_GUID));
    } else {
      const nextLayer = layers[currentLayerIndex + 1];
      dispatch(selectLayer(nextLayer.guid));
    }
  };
}

export function finishConfiguration() {
  return async (dispatch, getState) => {
    dispatch({
      type: "CLEAR_PACK_ERRORS",
    });
    const errors = await dispatch(
      clusterProfileFormActions.validateForm({ module: "clusterprofile" })
    );

    const layerErrors = errors.filter((error) =>
      error.field.includes("layers")
    );

    if (layerErrors.length) {
      dispatch({
        type: "SET_VALIDATION_PACK_ERRORS",
        errors: layerErrors.map((error) => ({
          name: error.packName,
          errors: [{ message: i18next.t("Invalid yaml configuration") }],
        })),
      });
      notifications.warn({
        message: i18next.t("Configuration is invalid"),
        description: i18next.t(
          "Please check the layers that display an exclamation mark"
        ),
      });

      dispatch(selectLayer(""));
      return;
    }

    dispatch(applyParams());

    const validationErrors =
      getState().clusterprofile.layerConfig.validationErrors || [];

    if (validationErrors.length === 0) {
      configurePacksModal.close();
    } else {
      dispatch(selectLayer(""));
      notifications.warning({
        message: i18next.t(
          "There are pack validation errors. Please fix them in order to continue"
        ),
      });
      return;
    }

    if (getState().clusterprofile.layerConfig.isEdit) {
      dispatch(
        clusterProfileFormActions.submit({ module: CLUSTER_PROFILE_MODULE })
      );
    }
  };
}

export function selectConfiguredLayer(layerGuid) {
  return (dispatch, getState) => {
    const layers = getLayers(getState());
    const selectedLayer = layers.find((layer) => layer.guid === layerGuid);

    if (selectedLayer?.description || layerGuid === OS_GUID) {
      dispatch(selectLayer(layerGuid));
    }
  };
}

export function selectAddNewLayer() {
  return (dispatch, getState) => {
    const areLayerConfigured = areAllLayersConfigured(getState());

    if (areLayerConfigured) {
      dispatch(selectLayer(ADD_GUID));
    }
  };
}

export function seeProfilePreview() {
  return (dispatch) => {
    dispatch(applyParams());
    dispatch(setPackStep("preview"));
    dispatch(selectLayer(""));
  };
}

export function setVersionExpandedKeys(versionGuids = []) {
  return {
    type: "SET_VERSION_EXPANDED_KEYS",
    expandedKeys: versionGuids,
  };
}

export function acknowledgeEditorNotifications() {
  return function thunk(dispatch, getState) {
    const currentLayer = getSelectedLayer(getState());

    dispatch({
      type: "ACKNOWLEDGE_EDITOR_NOTIFICATIONS",
      layerGuid: currentLayer.guid,
    });
  };
}

export const revertPackValuesModalService = new ModalService("packRevert");

export function openRevertPackModal() {
  return function thunk(dispatch, getState) {
    revertPackValuesModalService.open().then(() => {
      const state = getState();
      const defaultValues = getSelectedVersionDefaultValues(state);
      dispatch(onPacksFormChange("values", defaultValues));
      dispatch(resetPresetsValues());
    });
  };
}

export function resetPresetsValues() {
  return function thunk(dispatch, getState) {
    const packVersion = getSelectedPackVersion(getState());
    const defaultOptions = getDefaultPresetsFromValues(
      packVersion?.pack?.spec?.values
    );

    dispatch(
      packsStepFormActions.onChange({
        module: "packSteps",
        name: "presets",
        value: defaultOptions,
      })
    );
  };
}

export function onPresetsChange({ value, group, presets }) {
  return function thunk(dispatch, getState) {
    const state = getState();
    const packSteps = state.forms.packSteps?.data;
    const defaultValues = getSelectedVersionDefaultValues(state);
    const selectedPresets = presets.find(
      (preset) => preset.group === group && preset.value === value
    );

    let base =
      getPreviousPresetDelta({
        presetValues: packSteps.presets,
        values: packSteps.values,
        presets,
        group,
        defaultValues,
      }) || packSteps.values;

    const updatedValues =
      parseYAMLValues(base, selectedPresets) || packSteps.values;
    const updatedPresets = {
      ...(packSteps.presets || {}),
      [group]: value,
    };

    dispatch(
      packsStepFormActions.batchChange({
        module: "packSteps",
        updates: {
          presets: updatedPresets,
          values: updatedValues,
        },
      })
    );
    dispatch(applyParams());
  };
}

export function onEditorMarkersUpdated(markers) {
  return function thunk(dispatch, getState) {
    const currentLayer = getSelectedLayer(getState());
    const layers = getLayers(getState());
    const selectedIndex = layers.findIndex(
      (layer) => layer.guid === currentLayer.guid
    );

    dispatch({
      type: "UPDATE_FORM_ERRORS",
      module: "clusterprofile",
      errors: [
        {
          guid: `MARKER_ERROR-${currentLayer.guid}`,
          layerGuid: currentLayer.guid,
          packName: currentLayer.name,
          field: `layers.${selectedIndex}.config.values`,
          result: markers.length
            ? i18next.t("Layer {{layerName}} has invalid yaml configuration", {
                layerName: currentLayer.name,
              })
            : false,
        },
      ],
    });
  };
}
